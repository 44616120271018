import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { masterDataApi } from 'src/app/api.config';
import {
  ApiResponse,
  ArrayResult,
} from 'src/app/core/common/models/api-response';
import { PSL } from 'src/app/core/common/models/master-data/psl';
import { environment } from 'src/environments/environment';
import { URL_PARAM } from '../../../common/constants/routes.constant';
import {
  IGeneralItem,
  IPagination,
  IPagingResponse,
} from '../../../common/models/general.model';
import { IAdminService, IOrderByItem } from '../../models/admin.model';

@Injectable({
  providedIn: 'root',
})
export class PslSelectionService implements IAdminService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  addItem(item: PSL): Observable<PSL> {
    return this.http
      .post<ApiResponse<PSL>>(this.baseUrl + masterDataApi.psl.add, item)
      .pipe(
        map((res) => {
          return res.value;
        }),
      );
  }

  updateItem(item: PSL): Observable<PSL> {
    return this.http
      .post<ApiResponse<PSL>>(this.baseUrl + masterDataApi.psl.update, item)
      .pipe(
        map((res) => {
          return res.value;
        }),
      );
  }

  deleteItem(id: string): Observable<PSL> {
    return this.http
      .delete<
        ApiResponse<PSL>
      >(this.baseUrl + masterDataApi.psl.delete.replace(URL_PARAM.PSLId, id))
      .pipe(
        map((res) => {
          return res.value;
        }),
      );
  }

  getItemDetail(selectedId: string): Observable<PSL> {
    return this.http
      .get<
        ApiResponse<PSL>
      >(this.baseUrl + masterDataApi.psl.getById.replace(URL_PARAM.PSLId, selectedId))
      .pipe(
        map((res) => {
          return res.value;
        }),
      );
  }

  getAllItems(): Observable<PSL[]> {
    return this.http
      .get<ArrayResult<PSL>>(this.baseUrl + masterDataApi.psl.getPSLs)
      .pipe(map((sub) => sub && sub.results.map((c) => c.value)));
  }

  getList(_pagination: IPagination): Observable<IPagingResponse<IGeneralItem>> {
    throw new Error('Method not implemented.');
  }

  getPSLs(): Observable<PSL[]> {
    return this.http
      .get<ArrayResult<PSL>>(this.baseUrl + masterDataApi.psl.getPSLs)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getActivePSLs(): Observable<PSL[]> {
    return this.http
      .get<ArrayResult<PSL>>(this.baseUrl + masterDataApi.psl.getActivePSLs)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  orderItems(_items: IOrderByItem[]): Observable<IGeneralItem[]> {
    throw new Error('orderItems not implemented in PSL.');
  }
}
