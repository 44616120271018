<div class="history-container">
  <div class="history-data">
    <p-table
      [value]="changedLogs"
      dataKey="id"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50]"
      [loading]="loading"
      [paginator]="true"
      paginatorStyleClass="custom-paginator"
      tableStyleClass="custom-table"
      [scrollable]="true"
      scrollHeight="flex"
    >
      <ng-template #header>
        <tr class="w-full text-sm">
          <th pSortableColumn="modifiedAt" class="w-3">
            <div class="flex align-items-center">Modified At <p-sortIcon field="modifiedAt" /></div>
          </th>
          <th pSortableColumn="modifiedBy" class="w-3">
            <div class="flex align-items-center">Modified By <p-sortIcon field="modifiedBy" /></div>
          </th>
          <th pSortableColumn="action" class="w-3">
            <div class="flex align-items-center">Action <p-sortIcon field="action" /></div>
          </th>
          <th pSortableColumn="description" class="w-4">
            <div class="flex align-items-center">Description <p-sortIcon field="description" /></div>
          </th>
        </tr>
      </ng-template>
      <ng-template #body let-log>
        <tr class="w-full text-xs">
          <td>{{ log.modifiedAt | date : "yyyy/MM/dd hh:mm" }}</td>
          <td>{{ log.modifiedBy }}</td>
          <td>{{ log.action }}</td>
          <td>{{ log.description }}</td>
        </tr>
      </ng-template>
      <ng-template #emptymessage>
        <tr>
          <td colspan="12">No log found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <footer class="flex align-content-center justify-content-end mt-3">
    <p-button styleClass="btn-secondary-gray" (click)="close()">Close</p-button>
  </footer>
</div>
