import { NgClass } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';
import { ReviewSelectedChallangesComponent } from 'src/app/core/common/components/review-selected-challanges/review-selected-challanges.component';
import {
  SelectInputSolutionFormKey,
  SolutionTitle,
} from 'src/app/core/common/enums/select-solution.enum';
import { DataEntryCategory } from 'src/app/core/common/models/master-data/data-enty';
import {
  DiagnosisDataEntry,
  DiagnosisDataEntryCreateDto,
  DiagnosisDataEntryGetDto,
} from 'src/app/core/common/models/workflow/diagnosis-entry';
import { DiagnosisDataEntryService } from 'src/app/core/services/diagnosis-data-entry.service';
import { DiagnosisSolutionService } from 'src/app/core/services/diagnosis-solution.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { setAvailableSolutions } from 'src/app/core/store/actions/select-solution.action';
import { selectIsAvailableSolutionSelected } from 'src/app/core/store/selectors/select-solution.selectors';
import { DialogCommonService } from '../../../../../core/services/common/dialog-common.service';

@Component({
  selector: 'app-input-solutions',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    NgClass,
    InputTextModule,
    ReviewSelectedChallangesComponent,
    ButtonModule,
    SelectModule,
  ],
  templateUrl: './input-solutions.component.html',
  styleUrl: './input-solutions.component.scss',
})
export class InputSolutionsComponent implements OnInit {
  readonly #globalStore = inject(Store);
  activeRoute = inject(ActivatedRoute);
  storeService = inject(StoreService);
  fb = inject(FormBuilder);
  dataEntryService = inject(DiagnosisDataEntryService);
  diagnosisSolutionService = inject(DiagnosisSolutionService);
  diagnosisDataEntryService = inject(DiagnosisDataEntryService);
  dialogCommonService = inject(DialogCommonService);

  title = SolutionTitle;
  inputSolution!: FormGroup;
  isDisabledDropdown = signal(false);
  isEnableClearAllBtn = signal(false);

  diagnosisSessionId: string = '';
  requiredInputs: {
    [category: string]: {
      label: string;
      value: DiagnosisDataEntryCreateDto | string;
    }[];
  } = {};
  entryCategories: DataEntryCategory[] = [];
  enteredDataValues: DiagnosisDataEntry[] = [];

  ngOnInit() {
    this.storeService.getSessionId().subscribe((sid) => {
      this.diagnosisSessionId = sid;
      this.inputSolution = this.fb.group({});
      this.dataEntryService
        .getSuggestedDataEntryCategories(this.diagnosisSessionId)
        .subscribe((res) => {
          this.entryCategories = res;
          this.renderRequiredInputs();
          this.loadEnteredEntryValues();
          this.getSelectedSolution();
        });
    });
  }

  loadEnteredEntryValues() {
    this.diagnosisDataEntryService
      .getEnteredValues(this.diagnosisSessionId)
      .subscribe((res) => {
        if (res) {
          this.enteredDataValues = res;
          this.enteredDataValues.forEach((value) => {
            this.setEntryValue(value.dataEntryCategoryName, value);
          });
          this.getSelectedSolution();
        }
      });
  }

  onDropdownChanged(event: DropdownChangeEvent, entry: DataEntryCategory) {
    if (event.value === null) this.clearEntryValue(entry);
    else {
      const isConditionExisted =
        entry.solutionCondition &&
        entry.solutionCondition.condition.toLowerCase() !== 'other';

      if (isConditionExisted) {
        const inputControl = this.inputSolution.get(`input${entry.name}`);
        if (inputControl) {
          this.updateInputValue(entry, inputControl.value);
        }
      } else {
        const changedValue: DiagnosisDataEntryCreateDto = event.value;
        const entryValue: DiagnosisDataEntryCreateDto = {
          diagnosisId: this.diagnosisSessionId,
          dataEntryCategoryId: changedValue.dataEntryCategoryId,
          dataEntryValueId: changedValue.dataEntryValueId,
        };
        this.updateEntryValue(entryValue);
      }
    }
    this.isEnableClearAllBtn.set(this.checkFormControlValues());
  }

  onTextInputChanged(event: any, entry: DataEntryCategory) {
    this.updateInputValue(entry, event.target.value);
    this.isEnableClearAllBtn.set(this.checkFormControlValues());
  }

  updateInputValue(entry: DataEntryCategory, value: string) {
    if (entry.solutionCondition) {
      const defaultUoM = entry.solutionCondition.uoMCategory.uoMs.filter(
        (uom) => uom.isDefault === true,
      );
      if (defaultUoM.length > 0 && entry.dataEntryValues.length > 0) {
        const entryValue: DiagnosisDataEntryCreateDto = {
          diagnosisId: this.diagnosisSessionId,
          dataEntryCategoryId: entry.id,
          dataEntryValueId: entry.dataEntryValues[0].id,
          inputValue: value,
          uoMId: defaultUoM[0].id,
        };
        if (value.trim() !== '') this.updateEntryValue(entryValue);
        else this.clearEntryValue(entry);
      }
    }
  }

  clearEntryValue(value: DataEntryCategory) {
    const removedValue: DiagnosisDataEntryGetDto = {
      diagnosisId: this.diagnosisSessionId,
      dataEntryCategoryId: value.id,
      dataEntryValueId: value.id,
    };

    this.dataEntryService.removeEntryValue(removedValue).subscribe((res) => {
      if (res.isError) {
        this.showBlockStateWarning(res.errorMessage!);
      } else {
        this.#globalStore.dispatch(
          setAvailableSolutions({
            availableSolutions: res.results.map((s) => s.value),
          }),
        );
        this.setEntryValue(value.name, undefined);
      }
    });
  }

  updateEntryValue(value: DiagnosisDataEntryCreateDto) {
    this.dataEntryService.upsertEntryValue(value).subscribe((res) => {
      if (res.isError) {
        this.showBlockStateWarning(res.errorMessage!);
      } else {
        this.#globalStore.dispatch(
          setAvailableSolutions({
            availableSolutions: res.results.map((s) => s.value),
          }),
        );
      }
    });
  }

  getDefaultValue(entry: DataEntryCategory): string {
    if (
      entry.solutionCondition &&
      entry.solutionCondition?.condition !== 'Other' &&
      entry.solutionCondition !== null
    ) {
      const defaultUoM = entry.solutionCondition.uoMCategory.uoMs.filter(
        (uom) => uom.isDefault === true,
      );
      if (defaultUoM.length > 0) {
        return defaultUoM[0].name;
      }
    }

    return 'Select';
  }

  getSelectedSolution(): void {
    this.#globalStore
      .select(selectIsAvailableSolutionSelected)
      .subscribe((res) => {
        this.disableInputFields(res);
        const formControlHasValue = this.checkFormControlValues();
        this.isEnableClearAllBtn.set(res ? !res : formControlHasValue);
      });
  }

  disableInputFields(isSelectedSolution: boolean): void {
    Object.keys(this.inputSolution.controls).filter((key) =>
      isSelectedSolution
        ? this.inputSolution.controls[key].disable()
        : this.inputSolution.controls[key].enable(),
    );
    this.isDisabledDropdown.set(isSelectedSolution);
  }

  renderRequiredInputs(): void {
    if (this.entryCategories) {
      this.entryCategories.forEach((cate) => {
        if (cate.solutionCondition || cate.dataEntryValues.length > 0) {
          if (
            this.isInputControl(cate.inputType.uiControlName!) &&
            cate.solutionCondition
          ) {
            const inputCate = `input${cate.name}`;
            this.requiredInputs[inputCate] = [
              {
                label: inputCate,
                value: '0',
              },
            ];
            this.inputSolution.addControl(
              inputCate,
              new FormControl(null, Validators.required),
            );

            this.requiredInputs[cate.name] =
              cate.solutionCondition.uoMCategory.uoMs.map((value) => {
                return {
                  label: value.name,
                  value: {
                    diagnosisId: this.diagnosisSessionId,
                    dataEntryCategoryId: cate.id,
                    dataEntryValueId: cate.dataEntryValues[0].id,
                    uoMId: value.id,
                  },
                };
              });
          } else if (
            this.isDropdownControl(cate.inputType.uiControlName!) &&
            cate.dataEntryValues.length > 0
          ) {
            this.requiredInputs[cate.name] = cate.dataEntryValues.map(
              (value) => {
                return {
                  label: value.name,
                  value: {
                    diagnosisId: this.diagnosisSessionId,
                    dataEntryCategoryId: cate.id,
                    dataEntryValueId: value.id,
                  },
                };
              },
            );
          }

          this.inputSolution.addControl(
            cate.name,
            new FormControl(null, Validators.required),
          );
        }
      });
    }
  }

  setEntryValue(entryCategory: string, value?: DiagnosisDataEntry) {
    const cate = this.inputSolution.get(entryCategory);
    if (cate) {
      if (!value) {
        cate.reset();
        const inputControl = this.inputSolution.get(`input${entryCategory}`);
        if (inputControl) inputControl.reset();
        return;
      }

      if (value.inputValue) {
        const inputControl = this.inputSolution.get(`input${entryCategory}`);
        if (inputControl) inputControl.setValue(value.inputValue ?? '');

        if (value.uoM) cate.setValue(this.adaptToUoM(value));
      } else {
        cate.setValue(this.adaptToDropdown(value));
      }
    }
  }

  adaptToDropdown(dataEntry: DiagnosisDataEntry): DiagnosisDataEntryCreateDto {
    return {
      diagnosisId: dataEntry.diagnosisId,
      dataEntryCategoryId: dataEntry.dataEntryCategoryId,
      dataEntryValueId: dataEntry.dataEntryValueId,
    };
  }

  adaptToUoM(dataEntry: DiagnosisDataEntry): DiagnosisDataEntryCreateDto {
    return {
      diagnosisId: dataEntry.diagnosisId,
      dataEntryCategoryId: dataEntry.dataEntryCategoryId,
      dataEntryValueId: dataEntry.dataEntryValueId,
      uoMId: dataEntry.uoMId,
    };
  }

  isInputControl(uiControlName: string): boolean {
    return (
      uiControlName === SelectInputSolutionFormKey.InputDouble ||
      uiControlName === SelectInputSolutionFormKey.InputInteger
    );
  }

  isDropdownControl(uiControlName: string): boolean {
    return uiControlName === SelectInputSolutionFormKey.DropDown;
  }

  countControls = (control: AbstractControl): number => {
    if (control instanceof FormControl) {
      return 1;
    }

    if (control instanceof FormArray) {
      return control.controls.reduce(
        (acc, curr) => acc + this.countControls(curr),
        0,
      );
    }

    if (control instanceof FormGroup) {
      return Object.keys(control.controls)
        .map((key) => control.controls[key])
        .reduce((acc, curr) => acc + this.countControls(curr), 0);
    }

    return 0;
  };

  clearAllSelections() {
    this.dataEntryService
      .resetEntryValue(this.diagnosisSessionId)
      .subscribe((res) => {
        if (res.isError) {
          this.showBlockStateWarning(res.errorMessage!);
        } else {
          this.#globalStore.dispatch(
            setAvailableSolutions({
              availableSolutions: res.results.map((s) => s.value),
            }),
          );

          this.inputSolution.reset();
          this.isEnableClearAllBtn.set(false);
        }
      });
  }

  checkFormControlValues(): boolean {
    const excludeKeys: string[] = [];
    this.entryCategories.forEach((cate) => {
      if (!this.isDropdownControl(cate.inputType.uiControlName!)) {
        excludeKeys.push(cate.name!);
      }
    });
    const keysToCheck = Object.keys(this.inputSolution.controls).filter(
      (key) => !excludeKeys.includes(key),
    );
    return keysToCheck.some((key) => this.inputSolution.controls[key]?.value);
  }

  showBlockStateWarning(message: string): void {
    this.dialogCommonService.show(true, {
      title: 'Blocking session',
      message,
      closeLabel: 'Close',
      closable: false,
    });
  }
}
