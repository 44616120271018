import { URL_PARAM } from './core/common/constants/routes.constant';

export const apiConfig = {
  masterData: {
    getActiveChallenges: '/master/challenge/active',
    getChallengesByPSL: `/master/challenge/psl/${URL_PARAM.PSLId}`,
    getSubChallengesByGetChallengeId: '/master/sub-challenge/:challengeId',
    getActiveSubChallenges: '/master/sub-challenge/active',
  },
  diagnosisSession: {
    initialize: '/session/initialize/:externalId',
    restart: '/session/restart/:sessionId',
    inprogress: '/session/inprogress/:sessionId',
    complete: '/session/complete/:sessionId',
    approve: '/session/approve/:sessionId',
    back: `/session/back/:sessionId`,

    getById: '/session/:sessionId',
    getByExternalId: '/session/:externalId',
    getByCurrentUser: '/session/current-user',
    getSessionChallenges: '/session/:sessionId/challenges',
  },
  diagnosisDataEntry: {
    upsertEntryValue: '/session/entry-value/upsert',
    removeEntryValue: '/session/entry-value/remove',
    resetEntryValue: '/session/:sessionId/entry-value/reset',
    getEnteredValues: '/session/:sessionId/entry-value/entered-values',

    getSuggestedDataEntryCategories: '/session/:sessionId/entry-categories',
  },
  diagnosisSolution: {
    selectSolution: '/session/solution/add',
    unselectSolution: '/session/solution/remove',
    resetSolutionSelection: '/session/:sessionId/solution/reset',

    getAvailableSolutions: '/session/:sessionId/available-solutions',
    getSelectedSolutions: '/session/:sessionId/selected-solutions',
    getCombinedCompatibilities: '/session/:sessionId/solution-compatibilities',
  },
  diagnosisMetadata: {
    getSuggestedRequiredActions: '/session/:sessionId/suggested-actions',
    attachedSuggestedRequiredActions:
      '/session/:sessionId/suggested-action/attach',
    confirmRequiredAction: '/session/attached-action/update',
    getAttachedRequiredActions: '/session/:sessionId/attached-actions',
    getDiagnosisReferences: '/session/:sessionId/references',
  },
};

export const masterDataApi = {
  general: {
    statistic: '/general/statistic',
  },
  role: {
    getAllPermissions: '/authorization/permissions',
    getAllRoles: '/authorization/roles',
    getAllRolesWithDetails: '/authorization/roles/detail',
    getAssingedRoles: '/authorization/roles/assigned',
    getAssingedPermissions: '/authorization/permissions/assigned',
    updateRolesWithDetails: '/authorization/roles/update',
    updateRoleWithDetail: '/authorization/role/update',
    deleteRoleWithDetail: '/authorization/role/delete',
    addRoleWithDetail: '/authorization/role/add',
  },
  psl: {
    add: '/master/psl/add',
    update: '/master/psl/edit',
    delete: `/master/psl/delete/${URL_PARAM.PSLId}`,

    getById: `/master/psl/detail/${URL_PARAM.PSLId}`,
    getPSLs: '/master/psl/all',
    getActivePSLs: '/master/psl/active',
  },
  challenge: {
    add: '/master/challenge/add',
    update: '/master/challenge/edit',
    delete: `/master/challenge/delete/${URL_PARAM.ChallangeId}`,

    getById: `/master/challenge/detail/${URL_PARAM.ChallangeId}`,
    getAll: '/master/challenge/all',
    order: '/master/challenge/all/order',
  },
  subchallenge: {
    add: '/master/sub-challenge/add',
    update: '/master/sub-challenge/edit',
    delete: `/master/sub-challenge/delete/${URL_PARAM.SubChallangeId}`,

    getById: `/master/sub-challenge/detail/${URL_PARAM.SubChallangeId}`,
    getAll: '/master/sub-challenge/all',
  },
  session: {
    getAllSessions: '/session/all',
    getSessionHistory: `/session/${URL_PARAM.SessionId}/history`,
    restart: `/session/restart/${URL_PARAM.SessionId}`,
    cancel: `/session/cancel/${URL_PARAM.SessionId}`,
    restore: `/session/restore/${URL_PARAM.SessionId}`,
    block: `/session/block/${URL_PARAM.SessionId}`,
    unblock: `/session/unblock/${URL_PARAM.SessionId}`,
  },
};
