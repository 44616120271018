import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SaveDialogInfo } from '../../../models/dialog.model';

@Component({
  selector: 'app-save-dialog',
  standalone: true,
  imports: [ButtonModule, FormsModule],
  templateUrl: `./save-dialog.component.html`,
  styleUrl: './save-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveDialogComponent implements OnInit {
  title = 'Confirmation';
  message = 'Are you sure to performance this?';
  cancelLabel = 'Cancel';
  saveLabel = 'Save';

  #dialogRef = inject(DynamicDialogRef);
  #dialogConfig = inject(DynamicDialogConfig);

  ngOnInit(): void {
    const config: SaveDialogInfo = this.#dialogConfig.data;
    if (config) {
      this.title = config.title ?? this.title;
      this.message = config.message ?? this.message;
      this.cancelLabel = config.cancelLabel ?? this.cancelLabel;
      this.saveLabel = config.saveLabel ?? this.saveLabel;
    }
  }

  save(): void {
    this.#dialogRef.close(true);
  }

  cancel(): void {
    this.#dialogRef.close(false);
  }
}
