import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { apiConfig, masterDataApi } from 'src/app/api.config';
import { URL_PARAM } from 'src/app/core/common/constants/routes.constant';
import {
  ApiResponse,
  ArrayResult,
} from 'src/app/core/common/models/api-response';
import { DiagnosisHistory } from 'src/app/core/common/models/workflow/diagnosis-history';
import { DiagnosisSession } from 'src/app/core/common/models/workflow/diagnosis-session';
import { environment } from 'src/environments/environment';
import {
  IGeneralItem,
  IPagingResponse,
} from '../../../common/models/general.model';
import { IAdminService, IOrderByItem } from '../../models/admin.model';

@Injectable({
  providedIn: 'root',
})
export class ManageSessionService implements IAdminService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  block(sessionId: string): Observable<DiagnosisSession> {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        masterDataApi.session.block.replace(URL_PARAM.SessionId, sessionId),
    );
  }

  unblock(sessionId: string): Observable<DiagnosisSession> {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        masterDataApi.session.unblock.replace(URL_PARAM.SessionId, sessionId),
    );
  }

  cancel(sessionId: string): Observable<DiagnosisSession> {
    return this.http
      .get<
        ApiResponse<DiagnosisSession>
      >(this.baseUrl + masterDataApi.session.cancel.replace(URL_PARAM.SessionId, sessionId))
      .pipe(
        map((resp) => {
          return resp?.value;
        }),
      );
  }

  restore(sessionId: string): Observable<DiagnosisSession> {
    return this.http
      .get<
        ApiResponse<DiagnosisSession>
      >(this.baseUrl + masterDataApi.session.restore.replace(URL_PARAM.SessionId, sessionId))
      .pipe(
        map((resp) => {
          return resp?.value;
        }),
      );
  }

  addItem<DiagnosisSession>(
    _item: DiagnosisSession,
  ): Observable<DiagnosisSession> {
    // TODO: Will implement later
    throw new Error('Method not implemented.');
  }

  updateItem<DiagnosisSession>(
    _item: DiagnosisSession,
  ): Observable<DiagnosisSession> {
    // TODO: Will implement later
    throw new Error('Method not implemented.');
  }

  deleteItem<DiagnosisSession>(_id: string): Observable<DiagnosisSession> {
    // TODO: Will implement later
    throw new Error('Method not implemented.');
  }

  getAllSessions(): Observable<DiagnosisSession[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisSession>
      >(this.baseUrl + masterDataApi.session.getAllSessions)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getSessionHistory(sessionId: string): Observable<DiagnosisHistory[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisHistory>
      >(this.baseUrl + masterDataApi.session.getSessionHistory.replace(URL_PARAM.SessionId, sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getAllItems<T>(): Observable<T[]> {
    return this.http
      .get<ArrayResult<T>>(this.baseUrl + masterDataApi.session.getAllSessions)
      .pipe(
        map((sub) => {
          return sub?.results.map((c) => c.value) || [];
        }),
      );
  }

  getItemDetail<T>(selectedId: string): Observable<T> {
    return this.http
      .get<
        ApiResponse<T>
      >(this.baseUrl + apiConfig.diagnosisSession.getById.replace(URL_PARAM.SessionId, selectedId))
      .pipe(map((res) => res.value));
  }

  getList<T>(): Observable<IPagingResponse<T>> {
    // TODO: implement paging to get list
    throw new Error('getList not implemented in Session.');
  }

  orderItems(_items: IOrderByItem[]): Observable<IGeneralItem[]> {
    throw new Error('orderItems not implemented in Session.');
  }
}
