<div class="modal-container">
  <p-select
    [options]="(dataSource$ | async) || []"
    [(ngModel)]="selectedPSL"
    optionLabel="name"
    appendTo="body"
    styleClass="w-full"
    emptyMessage="No Result Found"
    placeholder="Select PSL"
  ></p-select>
  @if (challengeStore.selectedPSL().id && selectedPSL.id && challengeStore.selectedPSL().id !== selectedPSL.id) {
  <p class="text-yellow-500">Note: Changing the PSL will reset the Selected Challenges.</p>
  }

  <div class="btn-psl-action">
    <p-button label="Cancel" styleClass="btn btn-cancel" (click)="close()" />
    <p-button label="Select" styleClass="btn btn-select" (click)="selectItem()" />
  </div>
</div>
