<div class="container">
  <div class="col-3 p-0">
    <div class="step-wrapper-image">
      <img src="assets/diagnose_hero.png" alt="stepper" class="stepper" />
    </div>
  </div>
  <div class="col-9 p-0 flex align-content-center">
    <div class="step-horizontal">
      <p-steps [model]="initialStepper()" [readonly]="true" class="w-full" styleClass="step-horizontal-item w-full" />
    </div>
  </div>
</div>
