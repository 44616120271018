import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { PairChallenge } from '../challenges-sub-challenges/challenges-sub-challenges.model';
import { ChallengesSubChallengesStore } from '../challenges-sub-challenges/challenges-sub-challenges.store';

@Component({
  selector: 'app-selected-pair-challenges',
  standalone: true,
  imports: [ButtonModule, NgClass],
  templateUrl: './selected-pair-challenges.component.html',
  styleUrl: './selected-pair-challenges.component.scss',
})
export class SelectedPairChallengesComponent {
  readonly store = inject(ChallengesSubChallengesStore);

  removePairChallenge(pairChallenge: PairChallenge): void {
    if (pairChallenge) {
      this.store.removePairChallenge(pairChallenge);
    }
  }

  removeAllPairs(): void {
    this.store.clearPairChallenge();
  }
}
