import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CURRENT_STATUS } from 'src/app/core/common/constants/status.constant';
import {
  DiagnosisRequiredAction,
  DiagnosisRequiredActionUpdateDto,
} from 'src/app/core/common/models/workflow/diagnosis-metadata';
import { DiagnosisSession } from 'src/app/core/common/models/workflow/diagnosis-session';
import { DiagnosisSessionService } from 'src/app/core/services/diagnosis-session.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { DialogCommonService } from '../../../../../core/services/common/dialog-common.service';
import { GateActionsByCate } from './required-action.model';

@Component({
  selector: 'app-required-action',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, NgClass],
  templateUrl: './required-action.component.html',
  styleUrl: './required-action.component.scss',
})
export class RequiredActionComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #storeService = inject(StoreService);
  readonly #fb = inject(FormBuilder);

  allRequiredChecked = signal(false);
  isDesignApproved = signal(false);
  diagnosisSession?: DiagnosisSession;
  status = CURRENT_STATUS;

  formGroup: FormGroup = new FormGroup({});

  diagnosisSessionId: string = '';
  diagnosisSessionService = inject(DiagnosisSessionService);
  dialogCommonService = inject(DialogCommonService);

  attachedGateActions: DiagnosisRequiredAction[] = [];
  actionsByCate: GateActionsByCate[] = [];

  ngOnInit(): void {
    this.#storeService.getSessionId().subscribe((sid) => {
      this.diagnosisSessionId = sid;
      this.loadAttachedRequiredActions();
    });
  }

  loadAttachedRequiredActions() {
    this.diagnosisSessionService
      .getAttachedRequiredActions(this.diagnosisSessionId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.getIsDesignApproved();

        this.attachedGateActions = res;
        this.actionsByCate = this.groupGateActions(res);

        this.formGroup = this.#fb.group(
          this.generateFormControls(this.actionsByCate),
        );
        this.checkAllCheckboxChecked();
      });
  }

  groupGateActions(
    requiredActions: DiagnosisRequiredAction[],
  ): GateActionsByCate[] {
    //First, group the compatibilities by category
    if (requiredActions?.length) {
      const group = requiredActions.reduce(
        (
          acc: { [key: string]: DiagnosisRequiredAction[] },
          current: DiagnosisRequiredAction,
        ) => {
          const key = current.gateAction.gateActionCategoryName;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(current);
          return acc;
        },
        {},
      );

      //Get the categories and compatibilities related.
      return Object.keys(group).map((key) => ({
        category: key,
        gateActions: group[key].sort((a, b) =>
          a.gateAction.name.localeCompare(b.gateAction.name),
        ),
      }));
    }

    return [];
  }

  private generateFormControls(data: any[]): { [key: string]: any } {
    const controls: { [key: string]: any } = {};
    data.forEach((section) => {
      if (section.checkboxItem) {
        section.checkboxItem.forEach((item: any) => {
          controls[item.name] = [item.isChecked];
        });
      }
    });
    return controls;
  }

  onCheckboxChange(item: DiagnosisRequiredAction): void {
    this.actionsByCate.forEach((group) =>
      group.gateActions.forEach((checkbox) => {
        if (checkbox.gateAction.name === item.gateAction.name)
          checkbox.isCompleted = !checkbox.isCompleted;
      }),
    );

    const confirmedAction: DiagnosisRequiredActionUpdateDto = {
      diagnosisId: this.diagnosisSessionId,
      gateActionId: item.gateActionId,
      IsCompleted: item.isCompleted,
    };

    this.diagnosisSessionService
      .confirmRequiredAction(confirmedAction)
      .subscribe((res) => {
        if (res.isError) {
          this.dialogCommonService.show(true, {
            title: 'Blocking session',
            message: res.errorMessage!,
            closeLabel: 'Close',
            closable: false,
          });
        }
      });

    this.checkAllCheckboxChecked();
  }

  getIsDesignApproved(): void {
    this.#storeService.getIsDesignApproved().subscribe((isDesignApproved) => {
      this.isDesignApproved.set(isDesignApproved);
      this.loadDiagnosisSession();
    });
  }

  loadDiagnosisSession() {
    this.diagnosisSessionService
      .getById(this.diagnosisSessionId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.diagnosisSession = res;
      });
  }

  checkAllCheckboxChecked(): void {
    const allChecked = this.actionsByCate.every((group) =>
      group.gateActions.every((checkbox) => checkbox.isCompleted),
    );
    this.#storeService.dispatchRequiredCheckboxSelected(allChecked);
    this.allRequiredChecked.set(allChecked);
  }
}
