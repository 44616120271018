import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { apiConfig } from 'src/app/api.config';
import { ChallengesResponse } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelectChallengeService {
  #http = inject(HttpClient);
  private apiUrl = environment.api;

  getActiveChallenges(): Observable<ChallengesResponse> {
    return this.#http
      .get<ChallengesResponse>(
        this.apiUrl + apiConfig.masterData.getActiveChallenges,
      )
      .pipe(
        map((res: ChallengesResponse) => {
          return res;
        }),
      );
  }

  getChallengesByPSL(pslId: string): Observable<ChallengesResponse> {
    return this.#http
      .get<ChallengesResponse>(
        this.apiUrl +
          apiConfig.masterData.getChallengesByPSL.replace(':pslId', pslId),
      )
      .pipe(
        map((res: ChallengesResponse) => {
          return res;
        }),
      );
  }

  getActiveSubChallenges(): Observable<any> {
    return this.#http
      .get<any>(this.apiUrl + apiConfig.masterData.getActiveSubChallenges)
      .pipe(
        map((res: any) => {
          return res;
        }),
      );
  }

  getSubChallengesById(challengeId: string): Observable<any> {
    return this.#http
      .get<any>(
        this.apiUrl +
          apiConfig.masterData.getSubChallengesByGetChallengeId.replace(
            ':challengeId',
            challengeId,
          ),
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
      );
  }
}
