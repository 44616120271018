import { NgClass, NgOptimizedImage } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  Signal,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { Menu } from 'primeng/menu';
import { TagModule } from 'primeng/tag';
import { map } from 'rxjs';
import { ButtonLabels } from 'src/app/core/common/enums/select-solution.enum';
import { DialogCommonService } from 'src/app/core/services/common/dialog-common.service';
import { DiagnosisSessionService } from 'src/app/core/services/diagnosis-session.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { setIsDesignApproved } from 'src/app/core/store/actions/required-action.actions';
import { navigateToNextStep } from 'src/app/core/store/actions/router.action';
import {
  setChallenges,
  setScreenStates,
} from 'src/app/core/store/actions/select-challenge.actions';
import {
  setAvailableSolutions,
  setInputFields,
  setIsAvailableSolutionSelected,
  setIsSelectSolutionFlag,
} from 'src/app/core/store/actions/select-solution.action';
import { ChallengesSubChallengesStore } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.store';
import {
  LOCAL_VARS,
  REQUEST_PARAM,
  ROUTES,
} from '../../constants/routes.constant';
import {
  CURRENT_STATUS,
  STATUS_CLASS_MAPPING,
} from '../../constants/status.constant';
import { PSL } from '../../models/master-data/psl';
import { DiagnosisSession } from '../../models/workflow/diagnosis-session';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    AvatarModule,
    AvatarGroupModule,
    ButtonModule,
    NgClass,
    Menu,
    TagModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  readonly #globalStore = inject(Store);
  readonly globalStore = inject(Store);
  challengeStore = inject(ChallengesSubChallengesStore);

  readonly #destroyRef = inject(DestroyRef);
  readonly #authService = inject(MsalService);
  readonly #router = inject(Router);
  readonly #storeService = inject(StoreService);
  readonly #diagnosisSessionService = inject(DiagnosisSessionService);
  dialogCommonService = inject(DialogCommonService);

  userName = '';
  labelAvatar = '';
  blockingTime = 10;
  buttonLabel = ButtonLabels;
  ROUTES = ROUTES;
  status = CURRENT_STATUS;
  STATUS_CLASS_MAPPING = STATUS_CLASS_MAPPING;

  currentStep = signal(ROUTES.SELECT_CHALLENGES);
  nextStepButtonLabel = {
    [ROUTES.SELECT_CHALLENGES]: this.buttonLabel.InitializeDiagnosis,
    [ROUTES.SELECT_SOLUTIONS]: this.buttonLabel.ViewSummary,
    [ROUTES.VIEW_SUMMARY]: this.buttonLabel.CompleteDiagnosis,
    [ROUTES.REQUIRED_ACTIONS]: this.buttonLabel.ApprovedForDesign,
  };
  isDisableInitializeBtn = signal(false);
  isDisableViewSummaryBtn = toSignal(
    this.#storeService.getSelectedSolution().pipe(map((resp) => !resp)),
  );
  isHideApprovedForDesignBtn = signal(false);
  isDisableApprovedForDesignBtn = signal(false);
  diagnosisSessionId = signal<string>('');

  externalId?: string;
  diagnosisSessionStatus: string = '';
  diagnosisSession?: DiagnosisSession;

  items: Signal<MenuItem[]> = computed(() => [
    {
      label: this.challengeStore.selectedPSL().id
        ? `Selected PSL: ${this.challengeStore.selectedPSL().name}`
        : 'PSL Selection',
      disabled: this.currentStep() !== ROUTES.SELECT_CHALLENGES,
      command: () => {
        this.openPSLSelection();
      },
    },
    {
      label: 'Log out',
      styleClass: 'log-out',
      command: () => {
        this.logOut();
      },
    },
  ]);

  nextBtnDisabled = computed(() => {
    switch (this.currentStep()) {
      case ROUTES.SELECT_CHALLENGES:
        return !this.challengeStore.pairChallenges().length;
      case ROUTES.SELECT_SOLUTIONS:
        return this.isDisableViewSummaryBtn();
      case ROUTES.REQUIRED_ACTIONS:
        return this.isDisableApprovedForDesignBtn();
      default:
        return false;
    }
  });

  ngOnInit(): void {
    this.processRouteActions();
    this.getActiveAccountInfo();
    this.verifySessionRequest();
    this.initSession();
  }

  initSession() {
    this.getRequiredCheckboxSelected();
  }

  verifySessionRequest() {
    this.#storeService
      .getExternalId()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((eid) => {
        this.externalId = eid;
        if (this.externalId) {
          this.#diagnosisSessionService
            .getByExternalId(this.externalId)
            .subscribe({
              next: (res) => this.updateSessionData(res),
              error: () => {
                this.resetDiagnosisStatus();
              },
            });
        }
      });

    this.#storeService
      .getSessionId()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((sid) => {
        if (sid) {
          this.diagnosisSessionId.set(sid);
          this.loadDiagnosisSession();
        }
      });
  }

  loadDiagnosisSession() {
    this.#diagnosisSessionService
      .getById(this.diagnosisSessionId()!)
      .subscribe({
        next: (res) => this.updateSessionData(res),
        error: () => {
          this.resetDiagnosisStatus();
        },
      });
  }

  updateSessionData(session: DiagnosisSession) {
    if (session) {
      this.checkSessionValid(session);
      this.diagnosisSession = session;
      this.diagnosisSessionId.set(session.id || '');
      this.diagnosisSessionStatus = session.diagnosisStatus.name || '';

      this.isHideApprovedForDesignBtn.set(
        this.diagnosisSession?.diagnosisStatus?.name ===
          this.status.APPROVED_FOR_DESIGN &&
          this.currentStep() === ROUTES.REQUIRED_ACTIONS,
      );
      this.gotoPage(this.diagnosisSessionStatus);
    } else {
      this.resetDiagnosisStatus();
    }
  }

  checkSessionValid(session?: DiagnosisSession) {
    if (!session) return;

    if (this.isSessionBlocking(session, this.currentStep())) {
      this.openBlockingWarning(
        `This session has been blocked by [${session.modifiedBy}]. Try again later.`,
      );
    } else if (this.isSessionCancelled(session)) {
      this.resetDiagnosisStatus();

      this.dialogCommonService.show(true, {
        title: 'Cancelled Session',
        message: `This session has been cancelled. Please contact admin for more information.`,
        closeLabel: 'Close',
        closable: false,
      });
    } else if (this.isSessionRestarted(session)) {
      this.resetDiagnosisStatus();

      this.dialogCommonService.show(true, {
        title: 'Restarted Session',
        message: `This session has been reset. You cannot access it anymore.`,
        closeLabel: 'Close',
        closable: false,
      });
    }
  }

  isSessionBlocking(session: DiagnosisSession, stepName: string): boolean {
    if (
      (stepName === ROUTES.SELECT_SOLUTIONS ||
        session.diagnosisStatusName === CURRENT_STATUS.IN_PROGRESS) &&
      this.isBlockingTime(session)
    )
      return true;

    return false;
  }

  isBlockingTime(session: DiagnosisSession): boolean {
    const lastModified = new Date(session.modifiedAt).getTime();
    const expirationTime = lastModified + this.blockingTime * 60000;
    const currentTime = this.getCurrentUTCDate().getTime();

    return (
      this.userName !== '' &&
      this.userName.toLowerCase() !== session.modifiedBy.toLowerCase() &&
      session.isBlocking &&
      expirationTime > currentTime
    );
  }

  private getCurrentUTCDate() {
    const current = new Date();
    return new Date(current.getTime() + current.getTimezoneOffset() * 60000);
  }

  isSessionCancelled(session: DiagnosisSession) {
    return session.diagnosisStatus.name === CURRENT_STATUS.CANCELLED;
  }

  isSessionRestarted(session: DiagnosisSession) {
    return session.diagnosisStatus.name === CURRENT_STATUS.RESTARTED;
  }

  resetDiagnosisStatus(): void {
    this.diagnosisSession = undefined;
    this.diagnosisSessionId.set('');
    this.diagnosisSessionStatus = '';
    this.currentStep.set(ROUTES.SELECT_CHALLENGES);
  }

  getActiveAccountInfo(): void {
    const activeAccount = this.#authService.instance.getActiveAccount();

    this.userName = activeAccount?.name ? activeAccount?.name : '';
    //Get first letter from user name
    this.labelAvatar = this.userName
      .split(' ')
      .map((item) => item[0])
      .join('')
      .substring(0, 2);
  }

  logOut(): void {
    this.#authService.logoutRedirect();
  }

  processRouteActions(): void {
    const initializeDiagnosisScreen = () => {
      this.currentStep.set(ROUTES.SELECT_CHALLENGES);
      this.isDisableInitializeBtn.set(true);
    };
    const routeActions = {
      [ROUTES.SELECT_CHALLENGES]: initializeDiagnosisScreen,
      [ROUTES.SELECT_SOLUTIONS]: () =>
        this.currentStep.set(ROUTES.SELECT_SOLUTIONS),
      [ROUTES.VIEW_SUMMARY]: () => {
        this.currentStep.set(ROUTES.VIEW_SUMMARY);
        this.isDisableInitializeBtn.set(false);
      },
      [ROUTES.REQUIRED_ACTIONS]: () =>
        this.currentStep.set(ROUTES.REQUIRED_ACTIONS),
    };

    const routeKey = Object.values(ROUTES).find((route) =>
      this.#router.url.includes(route),
    );
    if (routeKey) {
      routeActions[routeKey]();
      this.currentStep.set(routeKey);
      if (this.currentStep() === ROUTES.SELECT_CHALLENGES)
        this.dispatchScreenState();
    }
  }

  handleRouterNavigate(): void {
    const dispatchNavigate = (
      pageRoute: string,
      sessionId: string,
      externalId?: string,
    ) => {
      this.globalStore.dispatch(
        navigateToNextStep({
          pageRoute,
          sessionId,
          externalId,
        }),
      );
    };
    dispatchNavigate(this.currentStep(), this.diagnosisSessionId());
  }

  navigateToNextScreen(): void {
    if (this.currentStep() === ROUTES.SELECT_CHALLENGES) {
      this.initializeSession();
    } else if (this.currentStep() === ROUTES.SELECT_SOLUTIONS) {
      this.currentStep.set(ROUTES.VIEW_SUMMARY);
      this.handleRouterNavigate();
    } else if (this.currentStep() === ROUTES.VIEW_SUMMARY) {
      this.completeDiagnosis();
    }

    if (
      this.currentStep() === ROUTES.REQUIRED_ACTIONS &&
      !this.isDisableApprovedForDesignBtn()
    ) {
      this.approveForDesign();
    }
  }

  backToPreviousScreen(): void {
    this.currentStep.update((currStep) =>
      currStep === ROUTES.REQUIRED_ACTIONS
        ? ROUTES.VIEW_SUMMARY
        : ROUTES.SELECT_SOLUTIONS,
    );

    this.logBackAction();

    this.handleRouterNavigate();
  }

  logBackAction() {
    if (
      this.currentStep() === ROUTES.SELECT_SOLUTIONS &&
      this.diagnosisSession?.diagnosisStatusName ===
        this.status.DIAGNOSIS_COMPLETED
    ) {
      this.#diagnosisSessionService
        .back(this.diagnosisSessionId())
        .subscribe((res) => {
          if (res.isError) {
            this.openBlockingWarning(res.errorMessage!);
          } else {
            this.diagnosisSession = res.value;
            this.diagnosisSessionStatus = res.value.diagnosisStatusName;
          }
        });
    }
  }

  gotoPage(status: string) {
    let nextPageUrl = this.externalId ? REQUEST_PARAM.ExternalId : '';
    switch (status) {
      case CURRENT_STATUS.IN_PROGRESS:
        if (this.currentStep() === ROUTES.VIEW_SUMMARY)
          nextPageUrl = ROUTES.VIEW_SUMMARY;
        else nextPageUrl = ROUTES.SELECT_SOLUTIONS;
        break;
      case CURRENT_STATUS.APPROVED_FOR_DESIGN:
        nextPageUrl = ROUTES.REQUIRED_ACTIONS;
        this.isHideApprovedForDesignBtn.set(true);
        break;
      case CURRENT_STATUS.DIAGNOSIS_COMPLETED:
        nextPageUrl = ROUTES.REQUIRED_ACTIONS;
        break;
    }
    this.currentStep.set(nextPageUrl);
    this.#router.navigate([
      `${nextPageUrl}/${this.externalId || this.diagnosisSessionId()}`,
    ]);
  }

  dispatchScreenState(): void {
    this.#globalStore.dispatch(
      setScreenStates({
        isInitializeDiagnosisScreen:
          this.currentStep() === ROUTES.SELECT_CHALLENGES,
      }),
    );
  }

  dispatchIsSelectSolutionFlag(options: { isEnable: boolean }) {
    this.#globalStore.dispatch(
      setIsSelectSolutionFlag({
        isIsSelectSolutionFlag: options.isEnable,
      }),
    );
  }

  restartWorkflow(): void {
    this.#diagnosisSessionService
      .restartSession(this.diagnosisSessionId()!)
      .subscribe(() => {
        this.resetWorkflowState();
        this.challengeStore.clearStore();
      });
  }

  resetWorkflowState() {
    this.initializeWorkflowStates();
    this.resetDiagnosisStatus();
    this.handleRouterNavigate();
  }

  dispatchIsDesignApproved(): void {
    this.#globalStore.dispatch(
      setIsDesignApproved({
        isDesignApproved: true,
      }),
    );
  }

  getRequiredCheckboxSelected(): void {
    this.#storeService.getRequiredCheckboxSelected().subscribe((res) => {
      if (this.diagnosisSessionStatus === this.status.APPROVED_FOR_DESIGN) {
        this.isDisableApprovedForDesignBtn.set(true);
      } else {
        this.isDisableApprovedForDesignBtn.set(!res);
      }
    });
  }

  initializeWorkflowStates(): void {
    this.#globalStore.dispatch(
      setChallenges({
        pairChallenges: [],
      }),
    );

    this.#globalStore.dispatch(
      setInputFields({
        isAllFieldSelected: false,
      }),
    );

    this.#globalStore.dispatch(
      setIsAvailableSolutionSelected({
        isSelectedSolutions: false,
      }),
    );

    this.#globalStore.dispatch(
      setAvailableSolutions({
        availableSolutions: [],
      }),
    );
  }

  initializeSession() {
    const subChallengeIds = this.challengeStore
      .pairChallenges()
      .map((item) => item.subChallengeId!);

    this.#diagnosisSessionService
      .initializeSession(this.externalId || '', subChallengeIds)
      .subscribe((res) => {
        this.diagnosisSessionId.set(res?.id);
        this.diagnosisSession = res;
        this.diagnosisSessionStatus =
          this.diagnosisSession!.diagnosisStatus.name;
        this.currentStep.set(ROUTES.SELECT_SOLUTIONS);
        this.handleRouterNavigate();
        this.dispatchScreenState();
      });
  }

  completeDiagnosis() {
    this.#diagnosisSessionService
      .completeSession(this.diagnosisSessionId()!)
      .subscribe((res) => {
        if (res.isError) {
          this.openBlockingWarning(res.errorMessage!);
        } else {
          this.diagnosisSession = res.value;
          this.diagnosisSessionStatus = res.value.diagnosisStatusName;

          this.currentStep.set(ROUTES.REQUIRED_ACTIONS);
          this.handleRouterNavigate();
          this.dispatchScreenState();
        }
      });
  }

  approveForDesign() {
    this.#diagnosisSessionService
      .approveSession(this.diagnosisSessionId()!)
      .subscribe((res) => {
        if (res.isError) {
          this.openBlockingWarning(res.errorMessage!);
        } else {
          this.getRequiredCheckboxSelected();
          this.isHideApprovedForDesignBtn.set(true);
          this.dispatchIsDesignApproved();
          this.handleRouterNavigate();
          this.dispatchScreenState();
          this.loadDiagnosisSession();
        }
      });
  }

  openPSLSelection() {
    this.dialogCommonService.openPSLSelection().subscribe((psl: PSL) => {
      if (this.challengeStore.selectedPSL().id !== psl.id) {
        localStorage.setItem(
          LOCAL_VARS.SelectedPSL,
          JSON.stringify({ name: psl.name, id: psl.id }),
        );
        this.challengeStore.clearStore();
        this.challengeStore.setSelectedPSL(psl);
        this.challengeStore.loadChallengeByPSL(psl.id);
      }
    });
  }

  openBlockingWarning(message: string) {
    this.dialogCommonService.show(true, {
      title: 'Blocking session',
      message,
      closeLabel: 'Close',
      closable: false,
    });
  }
}
