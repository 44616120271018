import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectScreenState } from 'src/app/core/store/selectors/select-challenge.selectors';
import { HeaderComponent } from '../../../core/common/components/header/header.component';
import { StepMasterComponent } from '../../../core/common/components/step-master/step-master.component';

@Component({
  selector: 'app-user-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, StepMasterComponent, NgClass],
  templateUrl: './user-layout.component.html',
  styleUrl: './user-layout.component.scss',
})
export class UserLayoutComponent implements OnInit {
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);

  isInitializeDiagnosisScreen = signal(false);

  ngOnInit(): void {
    this.getScreenState();
  }

  getScreenState(): void {
    this.#globalStore
      .select(selectScreenState)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isInitializeDiagnosisScreen.set(res);
      });
  }
}
