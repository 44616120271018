import { Routes } from '@angular/router';

export const ADMIN_ROUTES: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./common/admin-dashboard/admin-dashboard.component').then(
        (c) => c.AdminDashboardComponent,
      ),
  },
  {
    path: 'additional-info',
    loadComponent: () =>
      import(
        './additional-info-management/additional-info-listing/additional-info-listing.component'
      ).then((c) => c.AdditionalInfoListingComponent),
  },
  {
    path: 'data-entry',
    loadComponent: () =>
      import(
        './data-entry-management/entry-category-listing/entry-category-listing.component'
      ).then((c) => c.EntryCategoryListingComponent),
    children: [
      {
        path: 'category',
        loadChildren: () =>
          import(
            './data-entry-management/entry-category-listing/entry-category-listing.component'
          ).then((c) => c.EntryCategoryListingComponent),
      },
    ],
  },
  {
    path: 'role',
    loadComponent: () =>
      import('./role-management/role-listing/role-listing.component').then(
        (c) => c.RoleListingComponent,
      ),
  },
  {
    path: 'session',
    loadComponent: () =>
      import(
        './session-management/session-listing/session-listing.component'
      ).then((c) => c.SessionListingComponent),
    children: [
      {
        path: 'sesion/history',
        loadChildren: () =>
          import(
            './session-management/session-history/session-history.component'
          ).then((c) => c.SessionHistoryComponent),
      },
    ],
  },
  {
    path: 'solution',
    loadComponent: () =>
      import(
        './solution-management/solution-listing/solution-listing.component'
      ).then((c) => c.SolutionListingComponent),
  },
  {
    path: 'challenge',
    loadComponent: () =>
      import(
        './challenge-management/challenge-listing/challenge-listing.component'
      ).then((c) => c.ChallengeListingComponent),
  },
  {
    path: 'sub-challenge',
    loadComponent: () =>
      import(
        './sub-challenge-management/sub-challenge-listing/sub-challenge-listing.component'
      ).then((c) => c.SubChallengeListingComponent),
    children: [
      {
        path: 'sub-challenge/:id',
        loadChildren: () =>
          import(
            './sub-challenge-management/sub-challenge-detail/sub-challenge-detail.component'
          ).then((c) => c.SubChallengeDetailComponent),
      },
    ],
  },
  {
    path: 'psl',
    loadComponent: () =>
      import('./psl-management/psl-listing/psl-listing.component').then(
        (c) => c.PslListingComponent,
      ),
  },
  {
    path: 'solution-management',
    loadComponent: () =>
      import(
        './solution-management/solution-listing/solution-listing.component'
      ).then((c) => c.SolutionListingComponent),
  },
  {
    path: 'solution-category',
    loadComponent: () =>
      import(
        './solution-category/solution-category-listing/solution-category-listing.component'
      ).then((c) => c.SolutionCategoryListingComponent),
  },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./errors/not-found/not-found.component').then(
        (c) => c.NotFoundComponent,
      ),
  },
  {
    path: 'server-error',
    loadComponent: () =>
      import('./errors/server-error/server-error.component').then(
        (c) => c.ServerErrorComponent,
      ),
  },
  {
    path: 'un-authorised',
    loadComponent: () =>
      import('./errors/un-authorised/un-authorised.component').then(
        (c) => c.UnAuthorisedComponent,
      ),
  },
];
