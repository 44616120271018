@let hasSubChallenges = subChallenges().length;

<div class="challenge pd-16 overflow-x-auto overflow-y-hidden h-full">
  <h2 class="challenge_title">{{ challengeTitle() }}</h2>
  @defer (when challengeMasterDatas().length || challengeTitle() === challengeType.SubChallenge){ @if(hasSubChallenges || challengeTitle() ===
  challengeType.Challenge) {
  <p-table
    class="challenge_listbox"
    #selectChallengeTable
    [value]="hasSubChallenges ? subChallenges() : challengeMasterDatas()"
    [scrollable]="true"
    scrollHeight="flex"
    [globalFilterFields]="[hasSubChallenges ? 'name' : 'value.name']"
    [(selection)]="currentSelected"
    selectionMode="single"
    (onRowSelect)="handleRowSelection(challengeTitle())"
    (onRowUnselect)="handleRowSelection(challengeTitle())"
    [loading]="store.isLoading()"
  >
    <ng-template pTemplate="caption">
      <div>
        <input
          class="challenge_filtered"
          #filterInput
          type="text"
          placeholder="Filter by Name"
          (input)="selectChallengeTable.filterGlobal(filterInput.value, 'contains')"
          pInputText
        />
        <h4 class="challenge_name">{{ challengeName() }}</h4>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item" [pSelectableRowDisabled]="item.isDisabled" [ngClass]="{'disabled-row': item.isDisabled}">
        <td>{{ item.value?.name || item.name}}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr class="center-flex empty-data">
        <h3 class="content">No Result Found</h3>
      </tr>
    </ng-template>
  </p-table>
  } @else {
  <div class="challenge_content">
    <h3 class="title">No Data yet</h3>
    <span>Once a challenge is selected, you will see data here</span>
  </div>
  } } @placeholder { }
</div>
