import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { masterDataApi } from 'src/app/api.config';
import {
  ArrayResult,
  ObjectResult,
} from 'src/app/core/common/models/api-response';
import {
  Permission,
  Role,
  RolePayload,
  UserPermission,
} from 'src/app/core/common/models/authorization/role';
import { environment } from 'src/environments/environment';
import {
  IGeneralItem,
  IPagingResponse,
} from '../../../common/models/general.model';
import { IAdminService, IOrderByItem } from '../../models/admin.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService implements IAdminService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  getAllItems<T>(): Observable<T[]> {
    return this.http
      .get<
        ArrayResult<T>
      >(this.baseUrl + masterDataApi.role.getAllRolesWithDetails)
      .pipe(
        map((sub) => {
          return (sub && sub.results.map((c) => c.value)) || [];
        }),
      );
  }

  getItemDetail<T>(): Observable<T> {
    throw new Error('Method not implemented.');
  }

  getList<T>(): Observable<IPagingResponse<T>> {
    // TODO: implement paging to get list
    return of({
      page: 1,
      pageSize: 10,
      totalItems: 100,
      sortField: 'xx',
      sort: 'asc',
      items: [],
    });
  }

  getAllPermissions(): Observable<Permission[]> {
    return this.http
      .get<
        ArrayResult<Permission>
      >(this.baseUrl + masterDataApi.role.getAllPermissions)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getUserPermissions(): Observable<UserPermission[]> {
    return this.http
      .get<
        ArrayResult<UserPermission>
      >(this.baseUrl + masterDataApi.role.getAssingedPermissions)
      .pipe(
        map((res) => {
          return res?.results.map((c) => c.value) || [];
        }),
      );
  }

  getAssignedRoles(): Observable<Role[]> {
    return this.http
      .get<
        ArrayResult<Role>
      >(this.baseUrl + masterDataApi.role.getAssingedRoles)
      .pipe(map((sub) => sub && sub.results.map((c) => c.value)));
  }

  addItem(item: RolePayload): Observable<Role> {
    return this.http
      .post<
        ObjectResult<Role>
      >(this.baseUrl + masterDataApi.role.addRoleWithDetail, item)
      .pipe(map((sub) => sub?.value || {}));
  }

  updateItem(item: IGeneralItem): Observable<Role> {
    return this.http
      .post<
        ObjectResult<Role>
      >(this.baseUrl + masterDataApi.role.updateRoleWithDetail, item)
      .pipe(map((sub) => sub?.value || {}));
  }

  deleteItem(id: string): Observable<Role> {
    return this.http
      .delete<
        ObjectResult<Role>
      >(`${this.baseUrl + masterDataApi.role.deleteRoleWithDetail}/${id}`)
      .pipe(map((sub) => sub?.value || {}));
  }

  orderItems(_items: IOrderByItem[]): Observable<IGeneralItem[]> {
    throw new Error('Method not implemented in Role.');
  }
}
