import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectModule } from 'primeng/select';
import { map, Observable, of } from 'rxjs';
import { PslSelectionService } from 'src/app/core/admin-features/services/master-data/psl-selection.service';
import { PSL } from '../../../../../core/common/models/master-data/psl';
import { ChallengesSubChallengesStore } from '../challenges-sub-challenges/challenges-sub-challenges.store';

@Component({
  selector: 'app-psl-selection',
  standalone: true,
  imports: [SelectModule, FormsModule, ButtonModule, AsyncPipe],
  templateUrl: './psl-selection.component.html',
  styleUrl: './psl-selection.component.scss',
})
export class PslSelectionComponent implements OnInit {
  #pslService = inject(PslSelectionService);

  title = 'PSL Selection';
  selectedPSL: PSL = { name: '', id: '' };
  dataSource$: Observable<PSL[]> = of([]);
  challengeStore = inject(ChallengesSubChallengesStore);

  constructor(private dialogRef: DynamicDialogRef) {}

  ngOnInit() {
    this.dataSource$ = this.#pslService
      .getActivePSLs()
      .pipe(map((psls) => psls.map((psl) => ({ name: psl.name, id: psl.id }))));
    this.selectedPSL = { ...this.challengeStore.selectedPSL() };
  }

  selectItem(): void {
    if (this.selectedPSL) {
      this.dialogRef.close(this.selectedPSL);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
