<section [ngClass]="{ 'overflow-hidden': isInitializeDiagnosisScreen()}">
  <div class="header">
    <app-header />
  </div>
  <div class="wrapper flex-1 overflow-auto">
    <div class="step-master">
      <app-step-master />
    </div>
    <router-outlet></router-outlet>
  </div>
</section>
