export interface IQuickAccessItem {
  icon: string;
  name: QuickAccessCardNames;
  url?: string;
  iconExtend?: string;
  children?: IQuickAccessItemChildren[];
  expanded?: boolean;
}

export interface IQuickAccessItemChildren {
  name: QuickAccessCardNames;
  url: string;
}

export enum QuickAccessCardNames {
  Empty = '',
  AdditionalInfoManagement = 'Additional Info Management',
  ChallengeManagement = 'Challenge Management',
  SubChallengeManagement = 'Sub Challenge Management',
  PSLManagement = 'PSL Management',
  DataEntryManagement = 'Data Entry Management',
  RoleManagement = 'Role Management',
  SessionManagement = 'Session Management',
  SolutionManagement = 'Solution Management',
  SolutionCategory = 'Solution Category',
}

export const QUICK_ACCESS_ITEMS: IQuickAccessItem[] = [
  {
    icon: 'session-management-icon.svg',
    name: QuickAccessCardNames.SessionManagement,
    url: 'session',
  },
  {
    icon: 'user-management-icon.svg',
    name: QuickAccessCardNames.RoleManagement,
    url: 'role',
  },
  {
    icon: 'challenge-icon.svg',
    name: QuickAccessCardNames.ChallengeManagement,
    expanded: false,
    children: [
      {
        name: QuickAccessCardNames.ChallengeManagement,
        url: 'challenge',
      },
      {
        name: QuickAccessCardNames.SubChallengeManagement,
        url: 'sub-challenge',
      },
      {
        name: QuickAccessCardNames.PSLManagement,
        url: 'psl',
      },
    ],
  },
  {
    icon: 'solution-management-icon.svg',
    name: QuickAccessCardNames.SolutionManagement,
    children: [
      {
        name: QuickAccessCardNames.SolutionCategory,
        url: 'solution-category',
      },
      {
        name: QuickAccessCardNames.SolutionManagement,
        url: 'solution-management',
      },
    ],
  },
  {
    icon: 'data-entry-icon.svg',
    name: QuickAccessCardNames.DataEntryManagement,
    url: 'data-entry',
  },
  {
    icon: 'info-icon.svg',
    name: QuickAccessCardNames.AdditionalInfoManagement,
    url: 'additional-info',
  },
];
