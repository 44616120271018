<div class="navigationadmindesktop">
  <div class="hamburger-menu" (click)="toggleMenu()">
    <div class="icon-component">
      <div class="cat"><i class="pi pi-bars"></i></div>
    </div>
  </div>
  <div class="header">
    <img class="logo-icon" alt="halliburton-logo" src="assets/halliburton.svg" />
    <b class="d-workflow">3D Workflow </b>
    <div class="menu-app-title">
      <div class="header-titles"></div>
    </div>
    <div class="ienergy-toolbar">
      <p-menu #menu [model]="items" [popup]="true" styleClass="menu-user" />
      <p-avatar [label]="avatarLabel" class="mr-2" size="normal" shape="circle" styleClass="avatar-user" (click)="menu.toggle($event)" />
    </div>
  </div>
</div>
