import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectModule } from 'primeng/select';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ManageSessionService } from 'src/app/core/admin-features/services/diagnosis-session/manage-session.service';
import { DiagnosisHistory } from 'src/app/core/common/models/workflow/diagnosis-history';

@Component({
  selector: 'app-session-history',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    InputTextModule,
    TagModule,
    SelectModule,
    MultiSelectModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
  ],
  templateUrl: './session-history.component.html',
  styleUrl: './session-history.component.scss',
})
export class SessionHistoryComponent implements OnInit {
  changedLogs!: DiagnosisHistory[];

  loading: boolean = true;
  searchValue: string = '';

  #sessionService = inject(ManageSessionService);
  #dialogConfig = inject(DynamicDialogConfig);
  #dialogRef = inject(DynamicDialogRef);

  ngOnInit(): void {
    if (this.#dialogConfig.data.sessionId) {
      this.#sessionService
        .getSessionHistory(this.#dialogConfig.data.sessionId)
        .subscribe((res) => {
          this.changedLogs = res;
          this.loading = false;
        });
    }
  }

  close() {
    this.#dialogRef.close();
  }
}
