export const ROUTES = {
  SELECT_SOLUTIONS: 'select-solutions',
  REQUIRED_ACTIONS: 'required-actions',
  VIEW_SUMMARY: 'view-summary',
  SELECT_CHALLENGES: 'select-challenges',
};

export const REQUEST_PARAM = {
  ExternalId: 'eid',
  SessionId: 'sid',
};

export const URL_PARAM = {
  SessionId: ':sessionId',
  PSLId: ':pslId',
  ChallangeId: ':challengeId',
  SubChallangeId: ':subChallengeId',
};

export const LOCAL_VARS = {
  SelectedPSL: 'SelectedPSL',
};
