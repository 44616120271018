<div class="primary-nav" [class.expanded]="store.menuExpanded()">
  <a class="menu-item" routerLink="dashboard" routerLinkActive="active">
    <div class="nav-content">
      <i class="fa-solid fa-house nav-icon"></i>
    </div>
    <span class="menu-item-name">Dashboard</span>
  </a>
  @for (item of menuItems; track item.name) {
    <a class="menu-item" (click)="onClickMenuItem(item)" routerLinkActive="active">
      <div class="nav-content">
        <img src="/assets/{{ item.icon }}" alt="Access icon" class="nav-img" />
      </div>
      <span class="menu-item-name">{{ item.name }}</span>
      @if (item.children?.length) {
        <img src="/assets/chevron-right.svg" alt="Extend icon" class="justify-content-end icon-expanded hidden" [class.expanded]="item.expanded" />
      }
    </a>
    @if (item.children?.length) {
      <div class="menu-extend hidden" [class.expanded]="item.expanded">
        @for (itemChild of item.children; track itemChild.name) {
          <a class="menu-extend-item" [routerLink]="itemChild.url" routerLinkActive="active">
            <span class="menu-extend-item-name">{{ itemChild.name }}</span>
          </a>
        }
      </div>
    }
  }
</div>
