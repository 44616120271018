<div class="modal-dialog">
  <div class="modal-body mb-4">
    <p class="flex align-items-center gap-2"><img src="/assets/exclamation-triangle-warning.svg" class="modal-icon" /> <span>{{message}}</span></p>
  </div>
  @if (this.closable) {
  <footer class="flex align-content-center justify-content-end mt-3">
    <p-button styleClass="btn-contrast" (click)="close()">{{closeLabel}}</p-button>
  </footer>
  }
</div>
