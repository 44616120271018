import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  getState,
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap } from 'rxjs';
import {
  DiagnosisSolutionCompatibility,
  DiagnosisSolutionCreateDto,
} from 'src/app/core/common/models/workflow/diagnosis-solution';
import { DiagnosisSolutionService } from 'src/app/core/services/diagnosis-solution.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { ArrayResult } from '../../../../../core/common/models/api-response';
import { DialogCommonService } from '../../../../../core/services/common/dialog-common.service';
import {
  AvailableSolutionState,
  CompatibilityByCate,
} from './available-solutions.model';

const initialState: AvailableSolutionState = {
  diagnosisSessionId: '',
  diagnosisSolutions: [],
  isEnableUnselectAllBtn: false,
  solutionsByCategory: [],
  isEmptyAvailableSoltions: false,
  isShowLoadingSpinner: false,
};

export type AvailableSolutionStoreType = InstanceType<
  typeof AvailableSolutionStore
>;

export const AvailableSolutionStore = signalStore(
  withState(initialState),
  withComputed(({ solutionsByCategory }) => ({
    filteredSolutionsByCategory: computed(() => solutionsByCategory()),
  })),
  withMethods(
    (
      store,
      dialogCommonService = inject(DialogCommonService),
      diagnosisSolutionService = inject(DiagnosisSolutionService),
      storeService = inject(StoreService),
    ) => ({
      getDiagnosisSessionId: rxMethod<void>(
        pipe(
          switchMap(() => storeService.getSessionId()),
          tapResponse(
            (diagnosisSessionId: string) => {
              patchState(store, {
                diagnosisSessionId,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getDiagnosisSessionId:',
                error,
              );
            },
          ),
        ),
      ),
      loadAvailableSolutions: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSolutionService.getAvailableSolutions(diagnosisSessionId),
          ),
          tapResponse(
            (diagnosisSolutions: DiagnosisSolutionCompatibility[]) => {
              patchState(store, {
                diagnosisSolutions,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in loadAvailableSolutions:',
                error,
              );
            },
          ),
        ),
      ),
      unselectAllSolutions: rxMethod<void>(
        pipe(
          switchMap(() => {
            const { diagnosisSessionId } = getState(store);
            patchState(store, {
              isShowLoadingSpinner: true,
            });
            return diagnosisSolutionService.resetSolutionSelection(
              diagnosisSessionId,
            );
          }),
          tapResponse(
            (
              diagnosisSolutionsReseted: ArrayResult<DiagnosisSolutionCompatibility>,
            ) => {
              if (diagnosisSolutionsReseted.isError) {
                dialogCommonService.show(true, {
                  title: 'Blocking session',
                  message: diagnosisSolutionsReseted.errorMessage!,
                  closeLabel: 'Close',
                  closable: false,
                });
                patchState(store, {
                  isShowLoadingSpinner: false,
                });
              } else {
                patchState(store, {
                  diagnosisSolutions: diagnosisSolutionsReseted.results.map(
                    (c) => c.value,
                  ),
                  isEnableUnselectAllBtn: false,
                  isShowLoadingSpinner: false,
                });
              }
            },
            (error: HttpErrorResponse) => {
              patchState(store, {
                isShowLoadingSpinner: false,
              });
              console.error(
                'An error occurred in unselectAllSolutions:',
                error,
              );
            },
          ),
        ),
      ),
      selectOneSolution: rxMethod<DiagnosisSolutionCreateDto>(
        pipe(
          filter((selectedSolution) => !!selectedSolution),
          switchMap((selectedSolution: DiagnosisSolutionCreateDto) => {
            patchState(store, {
              isShowLoadingSpinner: true,
            });
            return diagnosisSolutionService.selectSolution(selectedSolution);
          }),
          tapResponse(
            (
              diagnosisSolutionsSelected: ArrayResult<DiagnosisSolutionCompatibility>,
            ) => {
              if (diagnosisSolutionsSelected.isError) {
                dialogCommonService.show(true, {
                  title: 'Blocking session',
                  message: diagnosisSolutionsSelected.errorMessage!,
                  closeLabel: 'Close',
                  closable: false,
                });
                patchState(store, {
                  isShowLoadingSpinner: false,
                });
              } else {
                storeService.dispatchSelectedSolution(true);
                storeService.dispatchInputFields(false);
                patchState(store, {
                  diagnosisSolutions: diagnosisSolutionsSelected.results.map(
                    (c) => c.value,
                  ),
                  isEnableUnselectAllBtn: true,
                  isShowLoadingSpinner: false,
                });
              }
            },
            (error: HttpErrorResponse) => {
              patchState(store, {
                isShowLoadingSpinner: false,
              });
              console.error('An error occurred in selectOneSolution:', error);
            },
          ),
        ),
      ),
      unselectOneSolution: rxMethod<DiagnosisSolutionCreateDto>(
        pipe(
          switchMap((selectedSolution: DiagnosisSolutionCreateDto) => {
            patchState(store, {
              isShowLoadingSpinner: true,
            });
            return diagnosisSolutionService.unselectSolution(selectedSolution);
          }),
          tapResponse(
            (
              diagnosisSolutionsUnselected: ArrayResult<DiagnosisSolutionCompatibility>,
            ) => {
              if (diagnosisSolutionsUnselected.isError) {
                dialogCommonService.show(true, {
                  title: 'Blocking session',
                  message: diagnosisSolutionsUnselected.errorMessage!,
                  closeLabel: 'Close',
                  closable: false,
                });
                patchState(store, {
                  isShowLoadingSpinner: false,
                });
              } else {
                patchState(store, {
                  isShowLoadingSpinner: false,
                  diagnosisSolutions: diagnosisSolutionsUnselected.results.map(
                    (c) => c.value,
                  ),
                });
              }
            },
            (error: HttpErrorResponse) => {
              patchState(store, {
                isShowLoadingSpinner: false,
              });
              console.error('An error occurred in unselectOneSolution:', error);
            },
          ),
        ),
      ),
      groupSolutionCompatibilities: rxMethod<DiagnosisSolutionCompatibility[]>(
        pipe(
          filter((compatibilities) => !!compatibilities),
          tapResponse(
            (compatibilities: DiagnosisSolutionCompatibility[]) => {
              if (compatibilities?.length) {
                const group = compatibilities.reduce(
                  (
                    acc: { [key: string]: DiagnosisSolutionCompatibility[] },
                    current: DiagnosisSolutionCompatibility,
                  ) => {
                    const key = current.availableSolution.solutionCategory.name;
                    if (!acc[key]) {
                      acc[key] = [];
                    }
                    acc[key].push(current);
                    return acc;
                  },
                  {},
                );

                const solutionsByCategory = Object.keys(group)
                  .map((key) => ({
                    category: key,
                    compatibilities: group[key].sort((a, b) =>
                      a.availableSolution.name.localeCompare(
                        b.availableSolution.name,
                      ),
                    ),
                  }))
                  .sort((a, b) => b.category.localeCompare(a.category));

                patchState(store, {
                  solutionsByCategory,
                });
              }
              return [];
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in groupSolutionCompatibilities:',
                error,
              );
            },
          ),
        ),
      ),
      isSolutionSelected: rxMethod<CompatibilityByCate[]>(
        pipe(
          tapResponse(
            (solutionsByCategory: CompatibilityByCate[]) => {
              const isSolutionSelected = solutionsByCategory.some((item) =>
                item.compatibilities.some(
                  (compatibility) => compatibility.isChecked,
                ),
              );
              storeService.dispatchSelectedSolution(isSolutionSelected);
              patchState(store, {
                isEnableUnselectAllBtn: isSolutionSelected,
                isEmptyAvailableSoltions: !solutionsByCategory.length,
              });
            },
            (error: HttpErrorResponse) => {
              console.error('An error occurred in isSolutionSelected:', error);
            },
          ),
        ),
      ),
      loadAvailableSolutionsFromStore: rxMethod<void>(
        pipe(
          switchMap(() => storeService.getAvailableSolutions()),
          tapResponse(
            (diagnosisSolutions: DiagnosisSolutionCompatibility[]) => {
              if (diagnosisSolutions?.length) {
                patchState(store, {
                  diagnosisSolutions,
                });
              }
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in loadAvailableSolutionsFromStore:',
                error,
              );
            },
          ),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({
      getDiagnosisSessionId,
      loadAvailableSolutions,
      groupSolutionCompatibilities,
      isSolutionSelected,
      loadAvailableSolutionsFromStore,

      diagnosisSessionId,
      diagnosisSolutions,
      solutionsByCategory,
    }) {
      getDiagnosisSessionId();
      loadAvailableSolutions(diagnosisSessionId);
      groupSolutionCompatibilities(diagnosisSolutions);
      isSolutionSelected(solutionsByCategory);
      loadAvailableSolutionsFromStore();
    },
  }),
);
