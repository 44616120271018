import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WarningDialogInfo } from '../../../models/dialog.model';

@Component({
  selector: 'app-warning-dialog',
  standalone: true,
  imports: [FormsModule, ButtonModule],
  templateUrl: './warning-dialog.component.html',
  styleUrl: './warning-dialog.component.scss',
})
export class WarningDialogComponent implements OnInit {
  title = 'Information';
  message = 'General information';
  closeLabel = 'Close';
  closable = true;

  #dialogRef = inject(DynamicDialogRef);
  #dialogConfig = inject(DynamicDialogConfig);

  ngOnInit(): void {
    const config: WarningDialogInfo = this.#dialogConfig.data;
    if (config) {
      this.title = config.title ?? this.title;
      this.message = config.message ?? this.message;
      this.closeLabel = config.closeLabel ?? this.closeLabel;
      this.closable = config.closable ?? this.closable;
    }
  }

  close(): void {
    window.self.close();
    this.#dialogRef.close(true);
  }
}
