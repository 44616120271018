import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap, tap } from 'rxjs';
import { DiagnosisSolutionService } from 'src/app/core/services/diagnosis-solution.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { ReviewSelectedSolutionsState } from './review-selected-solutions.model';

const initialState: ReviewSelectedSolutionsState = {
  diagnosisSessionId: '',
  selectedSolutions: [],
  isLoading: false,
};

export type ReviewSelectedSolutionsStoreType = InstanceType<
  typeof ReviewSelectedSolutionsStore
>;

export const ReviewSelectedSolutionsStore = signalStore(
  withDevtools('review-selected-solutions'),
  withState(initialState),
  withMethods(
    (
      store,
      diagnosisSolutionService = inject(DiagnosisSolutionService),
      storeService = inject(StoreService),
    ) => ({
      getDiagnosisSessionId: rxMethod<void>(
        pipe(
          switchMap(() => storeService.getSessionId()),
          tapResponse(
            (diagnosisSessionId: string) => {
              patchState(store, {
                diagnosisSessionId,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getDiagnosisSessionId:',
                error,
              );
            },
          ),
        ),
      ),
      loadSelectedSolutions: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          tap(() => patchState(store, { isLoading: true })),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSolutionService
              .getSelectedSolutions(diagnosisSessionId)
              .pipe(
                tapResponse(
                  (selectedSolutions) =>
                    patchState(store, { selectedSolutions }),
                  (error: HttpErrorResponse) => {
                    console.error(
                      'An error occurred in loadSelectedSolutions:',
                      error,
                    );
                  },
                  () => patchState(store, { isLoading: false }),
                ),
              ),
          ),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({
      getDiagnosisSessionId,
      loadSelectedSolutions,
      diagnosisSessionId,
    }) {
      getDiagnosisSessionId();
      loadSelectedSolutions(diagnosisSessionId);
    },
  }),
);
