import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  getState,
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';
import { SelectChallengeService } from 'src/app/core/services/select-challenge.services';
import { PSL } from '../../../../../core/common/models/master-data/psl';
import {
  ChallengesResponse,
  ChallengesState,
  PairChallenge,
  SubChallenge,
} from './challenges-sub-challenges.model';

const initialSubChallenge = {
  name: '',
  description: '',
  isActive: false,
  isValid: false,
  id: '',
};

const initialState: ChallengesState = {
  initialChallenges: null,
  initialSubChallenge,
  initialChallengeResult: {
    value: initialSubChallenge,
    isError: false,
    errorMessage: null,
    validationFailures: null,
  },
  selectedPSL: {
    name: '',
    id: '',
  },
  pairChallenges: [],
  selectedSubChallenges: [],
  isLoading: false,
};

export type ChallengesSubChallengesStoreType = InstanceType<
  typeof ChallengesSubChallengesStore
>;

export const ChallengesSubChallengesStore = signalStore(
  { providedIn: 'root' },
  withDevtools('challenges'),
  withState(initialState),
  withMethods(
    (store, selectChallengeService = inject(SelectChallengeService)) => ({
      loadActiveSubAndChallenge: rxMethod<void>(
        pipe(
          switchMap(() =>
            selectChallengeService.getActiveChallenges().pipe(
              tapResponse(
                (challenges: ChallengesResponse) => {
                  patchState(store, {
                    initialChallenges: challenges,
                  });
                },
                (error: HttpErrorResponse) => {
                  console.error(
                    'An error occurred in loadActiveSubaAndChallenge:',
                    error,
                  );
                },
              ),
            ),
          ),
        ),
      ),
      loadChallengeByPSL: rxMethod<string>(
        pipe(
          tap(() => patchState(store, { isLoading: true })),
          switchMap((selectedPslId: string) =>
            selectChallengeService.getChallengesByPSL(selectedPslId).pipe(
              tapResponse(
                (challenges: ChallengesResponse) => {
                  patchState(store, { initialChallenges: challenges });
                },
                (error: HttpErrorResponse) => {
                  console.error(
                    'An error occurred in loadActiveSubaAndChallenge:',
                    error,
                  );
                },
                () => patchState(store, { isLoading: false }),
              ),
            ),
          ),
        ),
      ),
      setInInitialChallenge: rxMethod<PairChallenge[]>(
        pipe(
          tapResponse(
            (pairChallenge) => {
              const challengesState = getState(store);
              const subChallengesMap = new Set(
                pairChallenge.map(
                  ({ challengeName, subChallengeName }) =>
                    `${challengeName}-${subChallengeName}`,
                ),
              );
              challengesState.initialChallenges?.results.forEach((item) =>
                item.value.subChallenges?.forEach((subChallenge) => {
                  subChallenge.isDisabled = subChallengesMap.has(
                    `${item.value.name}-${subChallenge.name}`,
                  );
                }),
              );
              patchState(store, {
                initialChallenges: challengesState.initialChallenges,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in setInInitialChallenge:',
                error,
              );
            },
          ),
        ),
      ),
      setSelectedPSL(selectedPSL: PSL) {
        patchState(store, { selectedPSL });
      },
      addPairChallenge(pairChallenge: PairChallenge) {
        patchState(store, {
          pairChallenges: [...store.pairChallenges(), pairChallenge],
          selectedSubChallenges: store.selectedSubChallenges().map((item) => ({
            ...item,
            isDisabled:
              item.id === pairChallenge.subChallengeId ? true : item.isDisabled,
          })),
        });
      },
      removePairChallenge(pairChallenge: PairChallenge) {
        patchState(store, {
          pairChallenges: store
            .pairChallenges()
            .filter(
              (challenge) =>
                challenge.subChallengeId !== pairChallenge.subChallengeId,
            ),
          selectedSubChallenges: store.selectedSubChallenges().map((item) => ({
            ...item,
            isDisabled:
              item.id === pairChallenge.subChallengeId
                ? false
                : item.isDisabled,
          })),
        });
      },
      clearPairChallenge() {
        patchState(store, {
          pairChallenges: [],
          selectedSubChallenges: store
            .selectedSubChallenges()
            .map((item) => ({ ...item, isDisabled: false })),
        });
      },
      setSelectedSubChallenges(subChallenges: SubChallenge[]) {
        patchState(store, (state) => ({
          selectedSubChallenges: subChallenges.map((item) => ({
            ...item,
            isDisabled: !!state.pairChallenges.find(
              (pair) => pair.subChallengeId === item.id,
            ),
          })),
        }));
      },
      clearStore() {
        patchState(store, initialState);
      },
    }),
  ),
);
