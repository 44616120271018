export const CURRENT_STATUS = {
  IN_PROGRESS: 'In-Progress',
  DIAGNOSIS_COMPLETED: 'Diagnosis Completed',
  APPROVED_FOR_DESIGN: 'Approved for Design',
  CANCELLED: 'Cancelled',
  RESTARTED: 'Restarted',
} as const;

export type StatusKeys = (typeof CURRENT_STATUS)[keyof typeof CURRENT_STATUS];

export const STATUS_CLASS_MAPPING: { [key: string]: string } = {
  'In-Progress': 'in-progress',
  'Approved for Design': 'approved',
  'Diagnosis Completed': 'diagnosis-completed',
  Cancelled: 'cancelled',
  Restarted: '',
};

export const ACTIVE_STATUS = [
  { name: 'Active', value: true },
  { name: 'Inactive', value: false },
];
