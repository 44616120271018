import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { apiConfig } from 'src/app/api.config';
import { environment } from 'src/environments/environment';
import { ApiResponse, ArrayResult } from '../common/models/api-response';
import { DiagnosisChallenge } from '../common/models/master-data/challenge';
import { GateAction, Reference } from '../common/models/master-data/metadata';
import {
  DiagnosisRequiredAction,
  DiagnosisRequiredActionUpdateDto,
} from '../common/models/workflow/diagnosis-metadata';
import { DiagnosisSession } from '../common/models/workflow/diagnosis-session';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisSessionService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  initializeSession(externalId: string, subChallenges: string[]) {
    return this.http
      .post<
        ApiResponse<DiagnosisSession>
      >(this.baseUrl + apiConfig.diagnosisSession.initialize.replace(':externalId', externalId), subChallenges)
      .pipe(map((res) => res.value));
  }

  restartSession(sessionId: string): Observable<DiagnosisSession> {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.restart.replace(':sessionId', sessionId),
    );
  }

  setSessionInprogress(sessionId: string): Observable<DiagnosisSession> {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.inprogress.replace(':sessionId', sessionId),
    );
  }

  completeSession(
    sessionId: string,
  ): Observable<ApiResponse<DiagnosisSession>> {
    return this.http.get<ApiResponse<DiagnosisSession>>(
      this.baseUrl +
        apiConfig.diagnosisSession.complete.replace(':sessionId', sessionId),
    );
  }

  approveSession(sessionId: string): Observable<ApiResponse<DiagnosisSession>> {
    return this.http.get<ApiResponse<DiagnosisSession>>(
      this.baseUrl +
        apiConfig.diagnosisSession.approve.replace(':sessionId', sessionId),
    );
  }

  back(sessionId: string): Observable<ApiResponse<DiagnosisSession>> {
    return this.http.get<ApiResponse<DiagnosisSession>>(
      this.baseUrl +
        apiConfig.diagnosisSession.back.replace(':sessionId', sessionId),
    );
  }

  getById(sessionId: string): Observable<DiagnosisSession> {
    return this.http
      .get<
        ApiResponse<DiagnosisSession>
      >(this.baseUrl + apiConfig.diagnosisSession.getById.replace(':sessionId', sessionId))
      .pipe(map((res) => res.value));
  }

  getByExternalId(externalId: string): Observable<DiagnosisSession> {
    return this.http
      .get<
        ApiResponse<DiagnosisSession>
      >(this.baseUrl + apiConfig.diagnosisSession.getByExternalId.replace(':externalId', externalId))
      .pipe(map((res) => res?.value));
  }

  getByCurrentUser(): Observable<DiagnosisSession> {
    return this.http
      .get<
        ApiResponse<DiagnosisSession>
      >(this.baseUrl + apiConfig.diagnosisSession.getByCurrentUser)
      .pipe(map((res) => res.value));
  }

  getSessionChallenges(sessionId: string): Observable<DiagnosisChallenge[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisChallenge>
      >(this.baseUrl + apiConfig.diagnosisSession.getSessionChallenges.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub?.results?.map((c) => c.value);
        }),
      );
  }

  getSuggestedRequiredActions(sessionId: string): Observable<GateAction[]> {
    return this.http
      .get<
        ArrayResult<GateAction>
      >(this.baseUrl + apiConfig.diagnosisMetadata.getSuggestedRequiredActions.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub?.results?.map((c) => c.value);
        }),
      );
  }

  attachedSuggestedRequiredActions(
    sessionId: string,
  ): Observable<DiagnosisRequiredAction[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisRequiredAction>
      >(this.baseUrl + apiConfig.diagnosisMetadata.attachedSuggestedRequiredActions.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub?.results?.map((c) => c.value);
        }),
      );
  }

  confirmRequiredAction(
    requiredAction: DiagnosisRequiredActionUpdateDto,
  ): Observable<ApiResponse<DiagnosisRequiredAction>> {
    return this.http.post<ApiResponse<DiagnosisRequiredAction>>(
      this.baseUrl + apiConfig.diagnosisMetadata.confirmRequiredAction,
      requiredAction,
    );
  }

  getAttachedRequiredActions(
    sessionId: string,
  ): Observable<DiagnosisRequiredAction[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisRequiredAction>
      >(this.baseUrl + apiConfig.diagnosisMetadata.getAttachedRequiredActions.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub?.results?.map((c) => c.value);
        }),
      );
  }

  getDiagnosisReferences(sessionId: string): Observable<Reference[]> {
    return this.http
      .get<
        ArrayResult<Reference>
      >(this.baseUrl + apiConfig.diagnosisMetadata.getDiagnosisReferences.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub?.results?.map((c) => c.value);
        }),
      );
  }
}
