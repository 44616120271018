<div class="solution">
  <h2 class="solution-title">Selected Solutions</h2>
  @if (store.isLoading()) {
  <p-progressSpinner ariaLabel="loading" class="center-flex" />
  } @else {
  <div class="solution-info">
    @for (compatibility of store.selectedSolutions(); track $index) {
    <div class="solution-info_wrapper">
      @if ( compatibility.compatibilityValue && compatibility.compatibilityValue.hexColor) {
      <div class="solution-info_color inline-block" [ngStyle]="{ 'background-color': compatibility.compatibilityValue!.hexColor}"></div>
      }
      <div class="solution-info_name inline-block">{{ compatibility.availableSolution.name }}</div>
    </div>
    } @empty {
    <p class="my-3 text-center">No Selected Solutions found.</p>
    }
  </div>
  }
</div>
