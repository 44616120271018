import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { REQUEST_PARAM } from 'src/app/core/common/constants/routes.constant';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { ChallengesSubChallengesComponent } from './challenges-sub-challenges/challenges-sub-challenges.component';
import { SelectedPairChallengesComponent } from './selected-pair-challenges/selected-pair-challenges.component';

@Component({
  selector: 'app-select-challenges-container',
  standalone: true,
  imports: [ChallengesSubChallengesComponent, SelectedPairChallengesComponent],
  templateUrl: './select-challenges-container.component.html',
  styleUrl: './select-challenges-container.component.scss',
  providers: [DialogService],
})
export class SelectChallengesContainerComponent implements OnInit {
  dialogService = inject(DialogService);
  dialogRef: DynamicDialogRef | undefined;

  readonly #activeRoute =
    inject(ActivatedRoute).paramMap.pipe(takeUntilDestroyed());
  readonly #storeService = inject(StoreService);

  ngOnInit(): void {
    this.#activeRoute.subscribe((params) => {
      const externalId = params.get(REQUEST_PARAM.ExternalId);
      const sessionId = params.get(REQUEST_PARAM.SessionId);
      if (externalId) this.#storeService.dispatchExternalId(externalId);
      if (sessionId) this.#storeService.dispatchSessionId(sessionId);
    });
  }
}
