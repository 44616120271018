import { Component } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-backdrop-loading-spinner',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './backdrop-loading-spinner.component.html',
  styleUrl: './backdrop-loading-spinner.component.scss',
})
export class BackdropLoadingSpinnerComponent {}
