<div class="container">
  <h2 class="summary_title">Diagnosis Summary</h2>
  <div class="overflow-auto">
    <p-table [columns]="summaryColumn" [value]="diagnosisSummary" dataKey="groupHeading" [expandedRowKeys]="expandedRows" class="summary-table">
      <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
        @let isWarning = rowData.groupHeading === sectionTypes.SolutionMessage;
        <tr>
          <td class="summary-body" [ngClass]="{ 'summary-warning': isWarning }">
            <a class="inline-block" href="#" [pRowToggler]="rowData">
              <i class="icon" [ngClass]="[expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right', isWarning ? 'warning' : '']"></i>
            </a>
            @for (column of columns; track $index) {
            <div class="summary-body_header inline-block" [ngClass]="{ 'warning': isWarning }">{{ rowData[column.header] }}</div>
            } @if (isWarning) {
            <i class="fa-solid fa-triangle-exclamation warning-icon"></i>
            }
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-rowData>
        @if (rowData.groupHeading === sectionTypes.Reference) {
        <tr class="summary-expan_tab">
          @for (item of rowData.items; track $index) {
          <div class="inline-block" (click)="swicthTab($index)">{{item.category}}</div>
          }
        </tr>
        <tr class="summary-expan_header">
          <th class="inline-block" pSortableColumn="solution">Solution</th>
          <th class="inline-block" pSortableColumn="hms-number">Hms Number</th>
          <th class="inline-block">URL</th>
          <th class="inline-block">Description</th>
        </tr>
        @for (reference of rowData.items[currentIndex()].references; track $index) {
        <tr class="summary-expan_header">
          <td class="inline-block">{{ reference.name }}</td>
          <td class="inline-block">{{ reference.hMSNumber }}</td>
          <td class="inline-block">
            <a [href]="reference.documentLink" target="_blank" [title]="reference.documentLink">Document</a>
          </td>
          <td class="inline-block">{{ reference.Description }}</td>
        </tr>
        } } @else {
        <tr>
          <td>
            <div class="summary-expan_wrapper">
              @for (item of rowData.items; track $index) { @if (item) { @if (item.category) {
              <div class="summary-expan_name">{{ item.category }}</div>
              }

              <ul>
                @if (!item.actions) {
                <li class="summary-expan_description">
                  <span><strong>{{ item.relationship }}</strong>: {{ item.message }}</span>
                </li>
                } @else { @for (action of item.actions; track action.name) {
                <li class="summary-expan_description">{{ action.name }} ({{ action.relatedSolutions }})</li>
                } }
              </ul>
              } }
            </div>
          </td>
        </tr>
        }
      </ng-template>
    </p-table>
  </div>
</div>
