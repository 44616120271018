import { NgClass } from '@angular/common';
import { Component, inject, input, output, signal } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ChallengeType } from 'src/app/core/common/enums/select-challenge.enum';
import {
  ChallengeResult,
  SubChallenge,
} from '../challenges-sub-challenges.model';
import { ChallengesSubChallengesStore } from '../challenges-sub-challenges.store';

@Component({
  selector: 'app-challenge-list',
  standalone: true,
  imports: [TableModule, NgClass, ProgressSpinnerModule],
  templateUrl: './challenge-list.component.html',
  styleUrl: './challenge-list.component.scss',
})
export class ChallengeListComponent {
  store = inject(ChallengesSubChallengesStore);

  subChallenges = input<SubChallenge[]>([]);
  challengeTitle = input<ChallengeType>();
  challengeName = input<string>('');
  challengeMasterDatas = input<ChallengeResult[] | SubChallenge[]>([]);
  currentSelected = signal<SubChallenge | ChallengeResult>(
    this.store.initialSubChallenge() || this.store.initialChallengeResult(),
  );
  challengeType = ChallengeType;
  selectedChallengeChange = output<ChallengeResult>();
  selectedSubChallengeChange = output<SubChallenge>();

  handleRowSelection(challengeTitle: ChallengeType | undefined): void {
    if (challengeTitle === this.challengeType.SubChallenge) {
      this.selectedSubChallengeChange.emit(
        this.currentSelected() as SubChallenge,
      );
    } else {
      this.selectedChallengeChange.emit(
        this.currentSelected() as ChallengeResult,
      );
    }
  }
}
