<div class="input">
  <h2 class="input_title">{{ title.Inputs }}</h2>
  @if (store.isLoading()) {
  <p-progressSpinner ariaLabel="loading" class="center-flex" />
  } @else {
  <div class="input-info">
    @for(value of store.enteredDataValues(); track $index){
    <div class="input-info_row">
      <div class="inline-block input-info_key">{{ value.dataEntryCategoryName }}</div>
      <div class="inline-block input-info_value">{{ getEnteredValue(value) }}</div>
    </div>
    } @empty {
    <div class="solution-empty">
      <h3 class="no-data">No Data yet</h3>
      <span>Once required inputs are filled, you will see data here</span>
    </div>
    }
  </div>
  }
</div>
